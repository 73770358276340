<template>
  <layout-login>
    <template v-slot:title>
      {{ __('interface.forgot-password') }}
    </template>

    <div class="ion-padding">{{ __('interface.forgot-password-email-sent') }}</div>

    <ion-item>
      <ion-label position="floating">{{ __('interface.forgot-password-code') }}</ion-label>
      <ion-input type="number" v-model="form.code" required/>
    </ion-item>
    <ion-item>
      <ion-label position="floating">{{ __('interface.email') }}</ion-label>
      <ion-input type="email" v-model="form.email" required/>
    </ion-item>

    <ion-item>
      <ion-label position="floating">{{ __('interface.new-password') }}</ion-label>
      <ion-input :type="showPassword.password ? 'text' : 'password'" v-model="form.password" required/>
      <ion-button fill="clear" slot="end" class="ion-align-self-end" icon-only style="margin-left:0;" @click="toggleShow('password')">
        <ion-icon :icon="showPassword.password ? eyeOff : eye"></ion-icon>
      </ion-button>
    </ion-item>
    <requirement-list v-if="form.password.length > 0" :requirements="passwordRequirements(form.password)"/>

    <ion-item>
      <ion-label position="floating">{{ __('interface.confirm-new-password') }}</ion-label>
      <ion-input :type="showPassword.confirm ? 'text' : 'password'" v-model="form.confirmPassword" required/>
      <ion-button fill="clear" slot="end" class="ion-align-self-end" icon-only style="margin-left:0;" @click="toggleShow('confirm')">
        <ion-icon :icon="showPassword.confirm ? eyeOff : eye"></ion-icon>
      </ion-button>
    </ion-item>
    <requirement-list v-if="form.confirmPassword.length > 0 || form.password.length > 0"
                      :requirements="repeatPasswordRequirements(form.password, form.confirmPassword)"/>

    <ion-button expand="full" class="ion-margin-vertical" @click="forgotPasswordSubmit"
                :disabled="!arePasswordsRequirementsMet(form.password, form.confirmPassword)">
      {{ __('interface.forgot-password-reset-password') }}
    </ion-button>
    <div class="ion-padding ion-text-center">
      <router-link to="/login">{{ __('interface.go-login') }}</router-link>
    </div>
  </layout-login>
</template>

<script>
import {Auth} from '@aws-amplify/auth'
import LayoutLogin from '../../layouts/LayoutLogin'
import {alertController, loadingController, IonIcon} from "@ionic/vue";
import RequirementList from '@/components/RequirementList.vue';
import AuthService from "@/views/AuthService";
import {passwordRequirements} from "@/utils/passwordRequirements";
import {eye, eyeOff} from 'ionicons/icons';

export default {
  name: 'Login',
  mixins: [passwordRequirements, AuthService],
  components: {
    LayoutLogin,
    RequirementList,
    IonIcon
  },
  data() {
    return {
      form: {
        email: '',
        code: '',
        password: '',
        confirmPassword: ''
      },
      loadingState: false,
      showPassword: {
        password: false,
        confirm: false
      }
    }
  },
  setup() {
    return {
      eye,
      eyeOff
    }
  },
  async ionViewWillEnter() {
    await this.checkNoAuth();
  },
  methods: {
    async forgotPasswordSubmit() {
      const loading = await loadingController.create({
        message: this.__('interface.loading'),
      });
      await loading.present();
      Auth.forgotPasswordSubmit(this.form.email, this.form.code, this.form.password).then(() => {
        this.$router.push('/login')
        this.showAlert('Info', this.__('interface.forgot-password-changed'));
        this.$tracking.event({
            eventName: 'changed-password',
            category: 'auth ',
            event: {
              username: this.form.username
            }
          });
      }).catch(error => {
        if(this.containsInvalidCharacter(this.form.password)) {
          this.showAlert('Error', error.message);
        } else {
          this.showAlert('Error', this.__('message.password-invalid-character'));
        }
      }).finally(() => {
        loading.dismiss();
      })
    },
    async showAlert(title, message) {
      return (await alertController.create({
        header: title,
        message: message,
        buttons: ['OK'],
      })).present();
    },
    containsInvalidCharacter(password) {
      const regexpFromCognitoMessage = /^[\S]+.*[\S]+$/
      return regexpFromCognitoMessage.test(password)
    },
    toggleShow (input) {
      this.showPassword[input] = !this.showPassword[input]
    }
  }
}
</script>
